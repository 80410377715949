import constRouter from '@/constants/constRouter';
import { checkNotLogin } from '@/middleware';

export default [
  {
    path: constRouter.AUTH_LOGIN.path,
    name: constRouter.AUTH_LOGIN.name,
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      ...constRouter.AUTH_LOGIN.meta,
    },
  },
];
