export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      action: 'read',
      resource: 'unAuth',
    },
  },
  // {
  //   path: '/register',
  //   name: 'auth-register',
  //   component: () => import('@/views/auth/Register.vue'),
  //   meta: {
  //     layout: 'full',
  //     action: 'read',
  //     resource: 'unAuth',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/theme/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      action: 'read',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/theme/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
];
