import permissionCode from '@/constants/permissionCode';

export default {
  // =======================AUTH=======================
  AUTH_LOGIN: {
    path: '/auth/login',
    name: 'auth.login',
    meta: {
      action: 'read',
      resource: 'unAuth',
      redirectIfLoggedIn: true,
    },
  },

  // =======================SETTING=======================
  SETTING: {
    path: '/account-setting',
    name: 'account-setting',
    meta: {
      action: 'read',
      resource: 'Auth',
    },
  },

  // =======================DASHBOARD=======================
  DASHBOARD: {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      action: 'read',
      resource: 'Auth',
    },
  },

  // =======================CONTRACT=======================
  CONTRACT: {
    path: '/contract',
    name: 'contract',
    meta: {
      action: permissionCode.CONTRACT_LIST.action,
      resource: permissionCode.CONTRACT_LIST.resource,
    },
  },

  CONTRACT_VIEW: {
    path: '/contract/:id',
    name: 'contract.view',
    meta: {
      action: permissionCode.CONTRACT_DETAIL.action,
      resource: permissionCode.CONTRACT_DETAIL.resource,
    },
  },
  // =======================PAYROLL=======================
  PAYROLL_LIST: {
    path: '/payroll',
    name: 'payroll',
    meta: {
      action: permissionCode.PAYROLL_LIST.action,
      resource: permissionCode.PAYROLL_LIST.resource,
    },
  },
  PAYROLL_DETAIL: {
    path: '/payroll/:id',
    name: 'payroll.id',
    meta: {
      action: permissionCode.PAYROLL_DETAIL.action,
      resource: permissionCode.PAYROLL_DETAIL.resource,
    },
  },
  PAYROLL_PAYMENT_LIST: {
    path: '/payroll-payment',
    name: 'payment',
    meta: {
      action: permissionCode.PAYROLL_PAYMENT_LIST.action,
      resource: permissionCode.PAYROLL_PAYMENT_LIST.resource,
    },
  },
  PAYROLL_COMPANY_LIST: {
    path: '/payroll-company',
    name: 'company',
    meta: {
      action: permissionCode.PAYROLL_COMPANY_LIST.action,
      resource: permissionCode.PAYROLL_COMPANY_LIST.resource,
    },
  },
  PAYROLL_COMPANY_DETAIL: {
    path: '/payroll-company/:id',
    name: 'company.detail',
    meta: {
      action: permissionCode.PAYROLL_COMPANY_DETAIL.action,
      resource: permissionCode.PAYROLL_COMPANY_DETAIL.resource,
    },
  },
  // =======================INVESMENT=======================
  INVESTMENT: {
    path: '/investmnet',
    name: 'investment',
    // meta: {
    //   action: permissionCode.CONTRACT_LIST.action,
    //   resource: permissionCode.CONTRACT_LIST.resource,
    // },
  },

  INVESTMENT_VIEW: {
    path: '/investment/:id',
    name: 'investment.view',
    // meta: {
    //   action: permissionCode.CONTRACT_LIST.action,
    //   resource: permissionCode.CONTRACT_LIST.resource,
    // },
  },

  WITHDRAW: {
    path: '/withdraw',
    name: 'withdraw',
    // meta: {
    //   action: permissionCode.CONTRACT_LIST.action,
    //   resource: permissionCode.CONTRACT_LIST.resource,
    // },
  },

  WITHDRAW_VIEW: {
    path: '/withdraw/:id',
    name: 'withdraw.view',
    // meta: {
    //   action: permissionCode.CONTRACT_LIST.action,
    //   resource: permissionCode.CONTRACT_LIST.resource,
    // },
  },

  // =======================CUSTOMER=======================
  CUSTOMER: {
    path: '/customer',
    name: 'customer',
    meta: {
      action: permissionCode.CUSTOMER_LIST.action,
      resource: permissionCode.CUSTOMER_LIST.resource,
    },
  },
  CUSTOMER_VIEW: {
    path: '/customer/view/:id',
    name: 'customer.view',
    meta: {
      action: permissionCode.CUSTOMER_DETAIL.action,
      resource: permissionCode.CUSTOMER_DETAIL.resource,
    },
  },

  // =======================SYSTEM=======================
  SYSTEM: {
    path: '/system',
    name: 'system',
    meta: {
      action: 'read',
      resource: 'Auth',
    },
  },
  SYSTEM_QUESTION: {
    path: '/system/question',
    name: 'system.question',
    meta: {
      action: permissionCode.QUESTION_LIST.action,
      resource: permissionCode.QUESTION_LIST.resource,
    },
  },
  SYSTEM_HISTORY_LOG: {
    path: '/system/history-log',
    name: 'system.historyLog',
  },
  SYSTEM_AUTHENTICATION: {
    path: '/system/permission',
    name: 'system.permission',
    meta: {
      action: permissionCode.PERMISSION_LIST.action,
      resource: permissionCode.PERMISSION_LIST.resource,
    },
  },

  // =======================USER=======================
  USER_STAFF: {
    path: '/user/staff',
    name: 'user.staff',
    meta: {
      action: permissionCode.STAFF_LIST.action,
      resource: permissionCode.STAFF_LIST.resource,
    },
  },
  USER_STAFF_NEW: {
    path: '/user/staff/new',
    name: 'user.staff.new',
    meta: {
      action: permissionCode.STAFF_CREATE.action,
      resource: permissionCode.STAFF_CREATE.resource,
    },
  },
  USER_STAFF_EDIT: {
    path: '/user/staff/edit/:id',
    name: 'user.staff.edit',
    meta: {
      action: permissionCode.STAFF_UPDATE.action,
      resource: permissionCode.STAFF_UPDATE.resource,
    },
  },
  USER_STAFF_DETAIL: {
    path: '/user/staff/:id',
    name: 'user.staff.detail',
    meta: {
      action: permissionCode.STAFF_DETAIL.action,
      resource: permissionCode.STAFF_DETAIL.resource,
    },
  },
};
