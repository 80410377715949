import Vue from 'vue';
// axios
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import constRouter from '@/constants/constRouter';
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig';

const axiosIns = axios.create({
  baseURL: `${process.env.VUE_APP_URL}`,
  withCredentials: false,
});

axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { response } = error;

      switch (+response.status) {
        case 403: {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Không đủ quyển',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Bạn không đủ quyền truy cập đường dẫn này',
            },
          });
          break;
        }
        case 419: {
          // 401 of refresh token
          localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName);
          localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName);
          document.location.href = constRouter.AUTH_LOGIN.path;
          break;
        }
        case 429: {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thao tác quá nhanh',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Hệ thống ghi nhận có nhiều lượt truy cập đồng thời, vui lòng thử lại sau ít phút',
            },
          });
          break;
        }
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
        case 505: {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hệ thống lỗi',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Hệ thống lỗi, vui lòng liên hệ bộ phận IT',
            },
          });
          break;
        }
        default:
          break;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Xảy ra lỗi',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: 'Vui lòng kiểm tra lại Internet, liên hệ bộ phận IT (nếu Internet hoạt động bình thường)',
        },
      });
    } else {
      // Something happened in setting up the request that triggered an Error, or err syntax
    }

    return Promise.reject(error);
  },
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
