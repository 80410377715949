import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.DASHBOARD.path,
    name: constRouter.DASHBOARD.name,
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: constRouter.DASHBOARD.meta,
  },
];
