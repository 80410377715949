export const name = 'test';
export default {
  state: () => ({
    phone: '',
    company: '',
  }),
  mutations: {
    setPhone(state, data) {
      // `state` is the local module state
      state.phone = data;
    },
  },
  getters: {
    getPhone(state) {
      return state.phone;
    },
  },
};
