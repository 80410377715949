import constRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: constRouter.CUSTOMER.path,
    name: constRouter.CUSTOMER.name,
    component: () => import('@/views/customer/customer-list/CustomerList.vue'),
    meta: {
      ...constRouter.CUSTOMER.meta,
      pageTitle: 'Khách hàng',
      breadcrumb: [
        {
          text: 'Khách hàng',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.CUSTOMER_VIEW.path,
    name: constRouter.CUSTOMER_VIEW.name,
    component: () => import('@/views/customer/customer-view/UsersView.vue'),
    meta: {
      ...constRouter.CUSTOMER_VIEW.meta,
    },
  },
];
