import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.PAYROLL_LIST.path,
    name: constRouter.PAYROLL_LIST.name,
    component: () => import('@/views/payroll/payroll-list/PayrollList.vue'),
    meta: {
      ...constRouter.PAYROLL_LIST.meta,
      pageTitle: 'iLương',
      breadcrumb: [
        {
          text: 'DS Hợp đồng',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.PAYROLL_DETAIL.path,
    name: constRouter.PAYROLL_DETAIL.name,
    component: () => import('@/views/payroll/payroll-detail/PayrollDetail.vue'),
    meta: {
      ...constRouter.PAYROLL_DETAIL.meta,
      pageTitle: 'iLương',
      breadcrumb: [
        {
          text: 'Chi tiết hợp đồng',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.PAYROLL_PAYMENT_LIST.path,
    name: constRouter.PAYROLL_PAYMENT_LIST.name,
    component: () => import('@/views/payroll/payroll-payment/PayrollPaymentList.vue'),
    meta: {
      ...constRouter.PAYROLL_PAYMENT_LIST.meta,
      pageTitle: 'iLương',
      breadcrumb: [
        {
          text: 'Yêu cầu thanh toán',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.PAYROLL_COMPANY_LIST.path,
    name: constRouter.PAYROLL_COMPANY_LIST.name,
    component: () =>
      import('@/views/payroll/payroll-company/payroll-company-list/PayrollCompanyList.vue'),
    meta: {
      ...constRouter.PAYROLL_COMPANY_LIST.meta,
      pageTitle: 'iLương',
      breadcrumb: [
        {
          text: 'DS Công ty',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.PAYROLL_COMPANY_DETAIL.path,
    name: constRouter.PAYROLL_COMPANY_DETAIL.name,
    component: () =>
      import('@/views/payroll/payroll-company/payroll-company-detail/payrollCompanyDetail.vue'),
    meta: {
      ...constRouter.PAYROLL_COMPANY_DETAIL.meta,
      pageTitle: 'iLương',
      breadcrumb: [
        {
          text: 'Chi tiết Công ty',
          active: true,
        },
      ],
    },
  },
];
