import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.CONTRACT.path,
    name: constRouter.CONTRACT.name,
    component: () => import('@/views/contract/contract-list/ContractList.vue'),
    meta: {
      ...constRouter.CONTRACT.meta,
      pageTitle: 'Hợp đồng',
      breadcrumb: [
        {
          text: 'Hợp đồng',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.CONTRACT_VIEW.path,
    name: constRouter.CONTRACT_VIEW.name,
    component: () => import('@/views/contract/contract-view/ContractView.vue'),
    meta: {
      ...constRouter.CONTRACT_VIEW.meta,
    },
  },
];
