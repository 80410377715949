import constRouter from '@/constants/constRouter';
import { auth } from '@/middleware';

export default [
  {
    path: constRouter.INVESTMENT.path,
    name: constRouter.INVESTMENT.name,
    component: () => import('@/views/investment/investment-list/InvestmentList.vue'),
    meta: {
      pageTitle: 'HĐ Đầu tư',
      breadcrumb: [
        {
          text: 'HĐ Đầu tư',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.INVESTMENT_VIEW.path,
    name: constRouter.INVESTMENT_VIEW.name,
    component: () => import('@/views/investment/investment-view/InvestmentView.vue'),
    // meta: {
    //   ...constRouter.CONTRACT_VIEW.meta,
    // },
  },
  {
    path: constRouter.WITHDRAW.path,
    name: constRouter.WITHDRAW.name,
    component: () => import('@/views/investment/withdraw/withdraw-list/withdrawList.vue'),
    meta: {
      pageTitle: 'DS rút tiền',
      breadcrumb: [
        {
          text: 'DS yêu cầu rút tiền',
          active: true,
        },
      ],
    },
  },
  {
    path: constRouter.WITHDRAW_VIEW.path,
    name: constRouter.WITHDRAW_VIEW.name,
    component: () => import('@/views/investment/withdraw/withdraw-view/withdrawView.vue'),
    // meta: {
    //   ...constRouter.CONTRACT_VIEW.meta,
    // },
  },
];
