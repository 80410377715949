import constRouter from '@/constants/constRouter';

export default [
  {
    path: constRouter.SETTING.path,
    name: constRouter.SETTING.name,
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      ...constRouter.SETTING.meta,
    },
  },
];
