const PATH = '/api/v1/admin';
export default {
  // =======================CUSTOMER=======================
  CUSTOMER_LIST: {
    action: `${PATH}/customers.get`,
    resource: 'customers',
  },
  CUSTOMER_DETAIL: {
    action: `${PATH}/customers/:id.get`,
    resource: 'customers',
  },
  // ======================CONTRACT=========================
  CONTRACT_LIST: {
    action: `${PATH}/contracts.get`,
    resource: 'contracts',
  },
  CONTRACT_DETAIL: {
    action: `${PATH}/contracts/:id.get`,
    resource: 'contracts',
  },
  CONTRACT_TYPE: {
    action: `${PATH}/contracts/type.get`,
    resource: 'contracts',
  },
  // =====================PAYROLL===============
  PAYROLL_COMPANY_LIST: {
    action: `${PATH}/companies.get`,
    resource: 'companies',
  },
  PAYROLL_COMPANY_DETAIL: {
    action: `${PATH}/companies/:id.get`,
    resource: 'companies',
  },
  PAYROLL_LIST: {
    action: `${PATH}/payrolls.get`,
    resource: 'payrolls',
  },
  PAYROLL_DETAIL: {
    action: `${PATH}/payrolls/:id.get`,
    resource: 'payrolls',
  },
  PAYROLL_DOWNLOAD: {
    action: `${PATH}/payrolls/:id/download-attachment.post`,
    resource: 'payrolls',
  },
  PAYROLL_APPROVE: {
    action: `${PATH}/payrolls/:id/approve.post`,
    resource: 'payrolls',
  },
  PAYROLL_INVEST: {
    action: `${PATH}/payrolls/:id/invest.post`,
    resource: 'payrolls',
  },
  PAYROLL_DISBURSE: {
    action: `${PATH}/payrolls/:id/disburse.post`,
    resource: 'payrolls',
  },
  PAYROLL_CONFIRM_DISBURSE: {
    action: `${PATH}/payrolls/:id/disburse-confirm.post`,
    resource: 'payrolls',
  },
  PAYROLL_CANCEL: {
    action: `${PATH}/payrolls/:id/cancel.post`,
    resource: 'payrolls',
  },
  PAYROLL_CONTRACT: {
    action: `${PATH}/payrolls/:id/contracts.get`,
    resource: 'payrolls',
  },
  PAYROLL_REGEN_CONTRACT: {
    action: `${PATH}/payrolls/:id/re-gen-contract.post`,
    resource: 'payrolls',
  },
  // PAYROLL_EXPORT_STATEMENT_FILE: {
  //   action: `${PATH}/payrolls/:id/export-statement-file.post`,
  //   resource: 'payrolls',
  // },
  PAYROLL_EXPORT_STATEMENT_FILE: {
    action: `${PATH}/payrolls/transactions/:id/export-statement-file.post`,
    resource: 'payrolls',
  },
  PAYROLL_REPAYMENT_HISTORY: {
    action: `${PATH}/payrolls/:id/repayment-history.get`,
    resource: 'payrolls',
  },
  PAYROLL_PAYMENT_LIST: {
    action: `${PATH}/payrolls/repayments.get`,
    resource: 'payrolls',
  },
  PAYROLL_PAYMENT_CONFIRM: {
    action: `${PATH}/payrolls/repayments/:id/repayment-confirm.post`,
    resource: 'payrolls',
  },
  PAYROLL_PAYMENT_REJECT: {
    action: `${PATH}/payrolls/repayments/:id/repayment-reject.post`,
    resource: 'payrolls',
  },
  PAYROLL_PAYMENT_SCHEDULE_UPDATE: {
    action: `${PATH}/payrolls/repayment-schedule/:id.post`,
    resource: 'payrolls',
  },
  // ====================PERMISSION===============
  PERMISSION_LIST: {
    action: `${PATH}/permissions.get`,
    resource: 'permissions',
  },
  PERMISSION_CREATE: {
    action: `${PATH}/permissions.post`,
    resource: 'permissions',
  },
  // ====================ROLE======================
  ROLE_LIST: {
    action: `${PATH}/roles.get`,
    resource: 'permissions',
  },
  ROLE_CREATE: {
    action: `${PATH}/roles.post`,
    resource: 'permissions',
  },
  ROLE_PERMISSION: {
    action: `${PATH}/roles/:id/permission.get`,
    resource: 'permissions',
  },
  // ====================QUESTION===============
  QUESTION_LIST: {
    action: `${PATH}/questions.get`,
    resource: 'questions',
  },
  QUESTION_CREATE: {
    action: `${PATH}/questions.post`,
    resource: 'questions',
  },
  QUESTION_UPDATE: {
    action: `${PATH}/questions/:id.put`,
    resource: 'questions',
  },
  QUESTION_DETAIL: {
    action: `${PATH}/questions/:id.get`,
    resource: 'questions',
  },
  QUESTION_DELETE: {
    action: `${PATH}/questions/:id.delete`,
    resource: 'questions',
  },
  // ====================STAFF===============
  STAFF_LIST: {
    action: `${PATH}/staffs.get`,
    resource: 'staffs',
  },
  STAFF_CREATE: {
    action: `${PATH}/staffs.post`,
    resource: 'staffs',
  },
  STAFF_DETAIL: {
    action: `${PATH}/staffs/:id.get`,
    resource: 'staffs',
  },
  STAFF_UPDATE: {
    action: `${PATH}/staffs/:id.put`,
    resource: 'staffs',
  },
  STAFF_DELETE: {
    action: `${PATH}/staffs/:id.delete`,
    resource: 'staffs',
  },
  STAFF_UPDATE_STATUS: {
    action: `${PATH}/staffs/:id.patch`,
    resource: 'staffs',
  },
  STAFF_UPDATE_PERMISSSION: {
    action: `${PATH}/staffs/:id/permissions.post`,
    resource: 'staffs',
  },
};
