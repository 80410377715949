import Vue from 'vue';
import Vuex from 'vuex';

import useJwt from '@/auth/jwt/useJwt';

// Modules
// import ecommerceStoreModule from '@/views/theme/apps/e-commerce/eCommerceStoreModule';
import ability from '@/libs/acl/ability';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

Vue.use(Vuex);

const modules = { app, appConfig, verticalMenu };

// Auto-load all store.ts in folder views
const requireComponent = require.context('../views', true, /store.js$/);
requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  modules[componentConfig.name] = componentConfig.default;
  modules[componentConfig.name].namespaced = true;
});
export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    SET_USER_INFO(state, data) {
      state.user = data;
      if (data.isSuperAdmin) {
        data.ability = [
          {
            action: 'manage',
            subject: 'all',
          },
        ];
      } else {
        data.ability = data.permissions.map((per) => ({
          action: per.code,
          subject: per.group,
        }));
      }
      data.ability.push({
        action: 'read',
        subject: 'Auth',
      });
      localStorage.setItem('data', JSON.stringify(data));
      ability.update(data.ability);
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    async fetchUserInfo({ commit }) {
      commit('SET_LOADING', true);
      try {
        const resp = await useJwt.profile();
        commit('SET_USER_INFO', resp.data.data);
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
      commit('SET_LOADING', false);
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  modules,
  strict: process.env.DEV,
});
